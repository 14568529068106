import styled from "styled-components";
import { useEffect, useState,useRef } from "react";
const BIRD_HEIGHT = 35;
const BIRD_WIDTH = 60;
const WALL_HEIGHT = 600;
const WALL_WIDTH = 400;
const GRAVITY = 8;
const OBJ_WIDTH = 52;
const OBJ_SPEED = 6;
const OBJ_GAP = 200;
function App() {
  const [isStart, setIsStart] = useState(false);
  const [birdpos, setBirspos] = useState(300);
  const [objHeight, setObjHeight] = useState(0);
  const [objPos, setObjPos] = useState(WALL_WIDTH);
  const [score, setScore] = useState(0);
  const scoreRef = useRef(null);
  const failRef = useRef(null);
  const purrRef = useRef(null);
  useEffect(() => {
    let intVal;
    if (isStart && birdpos < WALL_HEIGHT - BIRD_HEIGHT) {
      intVal = setInterval(() => {
        setBirspos((birdpos) => birdpos + GRAVITY);
      }, 24);
    }
    return () => clearInterval(intVal);
  });

  useEffect(() => {
    let objval;
    if (isStart && objPos >= -OBJ_WIDTH) {
      objval = setInterval(() => {
        setObjPos((objPos) => objPos - OBJ_SPEED);
      }, 24);

      return () => {
        clearInterval(objval);
      };
    } else {
      setObjPos(WALL_WIDTH);
      setObjHeight(Math.floor(Math.random() * (WALL_HEIGHT - OBJ_GAP)));
      if (isStart){
        setScore((score) => score + 1);
        if (scoreRef.current) {
          scoreRef.current.play();
        }
      } 
    }
  }, [isStart, objPos]);


  useEffect(() => {
    if(score == 50){
 

        purrRef.current.play()

    }
  },[score])

  useEffect(() => {
    let topObj = birdpos >= 0 && birdpos < objHeight;
    let bottomObj =
      birdpos <= WALL_HEIGHT &&
      birdpos >=
        WALL_HEIGHT - (WALL_HEIGHT - OBJ_GAP - objHeight) - BIRD_HEIGHT;

    if (
      objPos >= OBJ_WIDTH &&
      objPos <= OBJ_WIDTH + 80 &&
      (topObj || bottomObj)
    ) {
      setIsStart(false);
      setBirspos(300);
      setScore(0);
      if (failRef.current) {
        failRef.current.play();
      }
    }
  }, [isStart, birdpos, objHeight, objPos]);
  const handler = () => {
    if (!isStart) setIsStart(true);
    else if (birdpos < BIRD_HEIGHT) setBirspos(0);
    else setBirspos((birdpos) => birdpos - 80);
  };
  return (
    <div>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap" rel="stylesheet" />
      <title>
        $FLAPPYCAT on SOL
      </title>
    </head>
   
    <nav style={{ backgroundColor: '#f23a3a', padding: '10px 0' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }}>
        {/* Logo a la izquierda */}
     

        <img src="./images/falppycat.png" alt="Logo" style={{ width: 90 }} />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
          <h1 style={{ textAlign: 'center', margin: 0 }}>
            FLAPPY CAT (<span style={{ color: 'white' }}>$FLAPPYCAT</span>) on $SOL
          </h1>
          <img src="./images/SOLANA.png" alt="Solana Logo" style={{ width: 60 }} />
        </div>
    
      </div>
    </nav>
    <p style={{textAlign:'center', fontSize:25}}>FlappyCat wants to flap in the Solana chain, flap, flap... and collect some Sol!</p>
      <p style={{'fontSize':30, textAlign:'CENTER'}}>Score: {score}</p>
      <Home onClick={handler}>
        <Background height={WALL_HEIGHT} width={WALL_WIDTH}>
          {!isStart ? <Startboard>Click To Start</Startboard> : null}
          <Obj
            height={objHeight}
            width={OBJ_WIDTH}
            left={objPos}
            top={0}
            deg={180}
          />
          <Bird
            height={BIRD_HEIGHT}
            width={BIRD_WIDTH}
            top={birdpos}
            left={100}
          />
          <Obj
            height={WALL_HEIGHT - OBJ_GAP - objHeight}
            width={OBJ_WIDTH}
            left={objPos}
            top={
              WALL_HEIGHT - (objHeight + (WALL_HEIGHT - OBJ_GAP - objHeight))
            }
            deg={0}
          />
        </Background>
      </Home>

      {score >= 50 ? (
        <>
          <p style={{ fontWeight: "bold", textAlign: "center", fontSize:30 }}>
           Wow! you reached 50 score! FlappyCat is Purring of love!
          </p>
          <p style={{ fontWeight: "bold", textAlign: "center", fontSize:20 }}>
            Buy $FlappyCat!
          </p>
        </>
      ) : (
        <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
          Reach 50 score for surprise!
        </h2>
      )}
      <h1 style={{textAlign:'center'}}>CA: H5phBwWj8DeK1Cuu8vGEQsY72wGU6gH4oZ8Xogxupump</h1>
      <div style={{'display':'flex', justifyContent:'center'}}>
        <a href="https://pump.fun/coin/H5phBwWj8DeK1Cuu8vGEQsY72wGU6gH4oZ8Xogxupump">[pump.fun]</a>
      </div>
      <div style={{'display':'none'}}>
      <audio ref={scoreRef} src="./sounds/fail.mp3" />
      <audio ref={failRef} src="./sounds/fail2.mp3" />
      <audio ref={purrRef} src="./sounds/purr.mp3" />
      </div>
      <div style={{paddingTop:'20px'}}>&nbsp;</div>
    </div>
  );
}

export default App;

const Home = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./images/falppycat.png");
  background-repeat: repeat;
  background-size: 160px 100px;
  background-color: #f23a3a;
`;
const Background = styled.div`
  background-image: url("./images/background-day.png");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  overflow: hidden;
  border: 2px solid black;
`;

const Bird = styled.div`
  position: absolute;
  background-image: url("./images/falppycat.png");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const Obj = styled.div`
  position: relative;
  background-image: url("./images/pipe-green.png");
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  transform: rotate(${(props) => props.deg}deg);
`;

const Startboard = styled.div`
  position: relative;
  top: 49%;
  background-color: black;
  padding: 10px;
  width: 100px;
  left: 50%;
  margin-left: -50px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
`;

const ScoreShow = styled.div`
  text-align: center;
  background: transparent;
`;
